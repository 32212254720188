import type { InjectionKey } from 'vue'
// models
import { deleteFollowResponse, postFollowResponse } from '@/models/relationship'
// modules
import { isValueOf } from '@/utils/zod'
// repositories
import { isFetchError } from '@/composables/repository/useOhmyfetch'
import { useRepositoryFactory } from '@/composables/repository/useRepositoryFactory'

export const useFollow = () => {
  const repositoryFactory = useRepositoryFactory()
  const relationshipRepository = repositoryFactory.get('relationship')

  // フォロー中/フォローはずし中ユーザーのvketId
  const loadingFollowVketIds = ref<string[]>([])
  // 画面からフォローを外したユーザーのvketIdリスト
  const tempFollowCancelVketIds = ref<string[]>([])
  // 画面からフォローしたユーザーのvketIdリスト
  const tempFollowVketIds = ref<string[]>([])

  // フォローする
  const postFollow = async (vketId: string) => {
    try {
      loadingFollowVketIds.value.push(vketId)
      const response = await relationshipRepository.post.postFollow({
        vketId,
      })
      loadingFollowVketIds.value = loadingFollowVketIds.value.filter(
        (id) => id !== vketId
      )
      if (!isValueOf(postFollowResponse, response)) {
        console.error('An API response is different.')
        return false
      }
      tempFollowCancelVketIds.value = tempFollowCancelVketIds.value.filter(
        (id) => id !== vketId
      )
      tempFollowVketIds.value.push(vketId)
      return true
    } catch (e) {
      if (isFetchError(e)) {
        console.error(e)
        loadingFollowVketIds.value = loadingFollowVketIds.value.filter(
          (id) => id !== vketId
        )
        return false
      }
      throw e
    }
  }

  // フォロー外す
  const deleteFollow = async (vketId: string) => {
    try {
      loadingFollowVketIds.value.push(vketId)
      const response = await relationshipRepository.delete.deleteFollow({
        vketId,
      })
      loadingFollowVketIds.value = loadingFollowVketIds.value.filter(
        (id) => id !== vketId
      )
      if (!isValueOf(deleteFollowResponse, response)) {
        console.error('An API response is different.')
        return false
      }
      tempFollowCancelVketIds.value.push(vketId)
      tempFollowVketIds.value = tempFollowVketIds.value.filter(
        (id) => id !== vketId
      )
      return true
    } catch (e) {
      if (isFetchError(e)) {
        console.error(e)
        loadingFollowVketIds.value = loadingFollowVketIds.value.filter(
          (id) => id !== vketId
        )
        return false
      }
      throw e
    }
  }

  return {
    loadingFollowVketIds: readonly(loadingFollowVketIds),
    tempFollowCancelVketIds: readonly(tempFollowCancelVketIds),
    tempFollowVketIds: readonly(tempFollowVketIds),
    deleteFollow,
    postFollow,
  }
}

export type FollowComposable = ReturnType<typeof useFollow>

export const FollowInjectionKey: InjectionKey<FollowComposable> =
  Symbol('follow')
