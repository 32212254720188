import { z } from 'zod'
import { user } from '@/models/profiles'

// models
import { iconResponse } from '@/models/file'

// フォロー中のユーザー
export const followingData = z.object({
  id: z.number(),
  vketId: z.string(),
  name: z.string(),
  introduction: z.string().nullable(),
  icon: iconResponse,
  topPageAvatarId: z.number().nullable(),
  followStatus: z.boolean(),
  followCount: z.number(),
  followerStatus: z.boolean(),
  followerCount: z.number(),
  createdAt: z.string(),
  updatedAt: z.string(),
  sub: z.string(),
  userId: z.number(),
  platformCode: z.string(),
})
export type FollowingData = z.infer<typeof followingData>

export const updatedFollowingData = followingData.extend({
  isDisabled: z.boolean(),
  isFollowed: z.boolean(),
  isFollower: z.boolean().or(z.array(followingData)),
  buttonText: z.string(),
  isMyAccount: z.boolean(),
})

export type UpdatedFollowingData = z.infer<typeof updatedFollowingData>

// フォロワーユーザー
export const followerData = z.object({
  id: z.number(),
  vketId: z.string(),
  name: z.string(),
  introduction: z.string().nullable(),
  icon: iconResponse,
  topPageAvatarId: z.number().nullable(),
  followStatus: z.boolean(),
  followCount: z.number(),
  followerStatus: z.boolean(),
  followerCount: z.number(),
  createdAt: z.string(),
  updatedAt: z.string(),
  sub: z.string(),
  userId: z.number(),
  platformCode: z.string(),
})
export type FollowerData = z.infer<typeof followerData>

export const updatedFollowerData = followerData.extend({
  isDisabled: z.boolean(),
  isFollowed: z.boolean(),
  isFollower: z.boolean().or(z.array(followerData)),
  buttonText: z.string(),
  isMyAccount: z.boolean(),
})
export type UpdatedFollowerData = z.infer<typeof updatedFollowerData>

export const mutualFollowingData = user.extend({})
// 相互フォロワーユーザー
export type MutualFollowingData = z.infer<typeof mutualFollowingData>

/* get */
// フォロー中のユーザー一覧取得リクエスト
export const getFollowingsRequest = z.object({
  vketId: z.string(),
  limit: z.number(),
  offset: z.number(),
  name: z.string().optional(),
})
export type GetFollowingsRequest = z.infer<typeof getFollowingsRequest>
// フォロー中のユーザー一覧取得レスポンス
export const getFollowingsResponse = z.object({
  users: z.array(followingData),
})
export type GetFollowingsResponse = z.infer<typeof getFollowingsResponse>

// フォロワー一覧取得リクエスト
export const getFollowersRequest = z.object({
  vketId: z.string(),
  limit: z.number(),
  offset: z.number(),
  name: z.string().optional(),
})
export type GetFollowersRequest = z.infer<typeof getFollowersRequest>
// フォロワー一覧取得レスポンス
export const getFollowersResponse = z.object({
  users: z.array(followerData),
})
export type GetFollowersResponse = z.infer<typeof getFollowersResponse>

// 相互フォロワー一覧取得リクエスト
export const getMutualFollowingsRequest = z.object({
  name: z.string().optional(),
  limit: z.number(),
  offset: z.number(),
})
export type GetMutualFollowingsRequest = z.infer<
  typeof getMutualFollowingsRequest
>
// 相互フォロワー一覧取得レスポンス
export const getMutualFollowingsResponse = z.object({
  users: z.array(mutualFollowingData),
})
export type GetMutualFollowingsResponse = z.infer<
  typeof getMutualFollowingsResponse
>

/* post */
// フォローリクエスト
export const postFollowRequest = z.object({
  vketId: z.string(),
})
export type PostFollowRequest = z.infer<typeof postFollowRequest>
// フォローレスポンス
export const postFollowResponse = z.object({
  success: z.object({
    message: z.string(),
  }),
})
export type PostFollowResponse = z.infer<typeof postFollowResponse>

/* delete */
// フォロー解除リクエスト
export const deleteFollowRequest = z.object({
  vketId: z.string(),
})
export type DeleteFollowRequest = z.infer<typeof deleteFollowRequest>
// フォロー解除レスポンス
export const deleteFollowResponse = z.object({
  success: z.object({
    message: z.string(),
  }),
})
export type DeleteFollowResponse = z.infer<typeof deleteFollowResponse>
